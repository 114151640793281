@import "~@/styles/theme/main.scss";
.developed__by {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 10px;
    font-weight: 500;
    margin-top: 10px;
    color: grey;

    a {
      text-decoration: underline;
    }
  }
}
