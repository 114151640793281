@import "~@/styles/theme/main.scss";
.section {
  padding-block: $spacing-10;

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .wrapper {
      width: 100%;
      max-width: 1500px;
      padding: $spacing-3;
      margin: auto;

      & h2 {
        font-weight: 700;
        font-size: 36px;

        & strong {
          color: $primary-600;

          @include dark-mode {
            color: $primary-200;
          }
        }
      }

      .images-container {
        margin-top: $spacing-10;
      }
    }
  }
}
