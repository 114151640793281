@import "~@/styles/theme/main.scss";
.order-skeleton {
  @include skeleton($width: 145px, $height: 44px);
}

.container {
  padding-inline: var(--spacing-2);
  padding-block: var(--spacing-3);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-skeleton {
  @include skeleton($width: 120px, $height: 16px, $secondary: true);
}

.icon-skeleton {
  @include skeleton($width: 24px, $height: 24px, $secondary: true);
}
