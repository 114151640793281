@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-inline: auto;

    & h2 {
      font-size: 32px;
      font-weight: 400;
      text-align: center;
    }

    .cards-wrapper {
      margin-inline: auto;
      padding-top: $spacing-10;
      padding-bottom: $spacing-20;
      display: flex;
      flex-wrap: wrap;
      gap: $spacing-14;
      justify-content: center;
    }
  }
}
