@import "~@/styles/theme/main.scss";
.image-wrapper {
  width: 100%;
  max-width: 250px !important;
  cursor: pointer;

  @include media-tablet {
    max-width: max-content !important;
  }

  .image {
    max-width: 250px;
    align-items: center;
    object-fit: contain;
    max-height: 250px !important;

    @include media-phone {
      max-height: 80px !important;
    }
  }
}
