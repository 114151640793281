@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  padding: $spacing-10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-4;
  min-height: 80vh;
  overflow: hidden;

  @include media-tablet {
    flex-direction: column;
    align-items: center;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    align-items: flex-start;

    @include media-tablet {
      align-items: center;
    }

    &__title {
      color: $text-primary;
      font-size: 60px;
      font-weight: 600;

      @include media-tablet {
        font-size: 32px;
      }
    }

    &__description {
      color: text-secondary;
      font-size: 18px;
      font-weight: 400;

      @include media-tablet {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &__row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $spacing-10;

      @include media-tablet {
        justify-content: center;
      }
    }

    &__card {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &__wrapper {
        width: 100%;
        max-width: 450px;
        @include default-shape;
        background-color: $background-paper;
        padding: $spacing-6;
        display: flex;
        flex-direction: column;
        gap: $spacing-4;
        position: relative;

        &__input {
          @include default-shape;
          background-color: $background-default;
          color: $text-primary;
          width: 100%;
          height: 56px;
          padding-left: $spacing-3;
          outline: none;
          margin-inline: auto;

          :focus {
            outline: none;
          }
        }
      }

      &__button {
        @include default-shape;
        @include background-with-contrast($primary-500);

        width: 100%;
        display: flex;
        height: 56px;
        align-items: center;
        justify-content: center;
        gap: $spacing-4;

        & .icon {
          @include background-with-contrast($primary-500);
        }
      }
    }
  }
}

.list-container {
  @include default-shape;
  @include background-with-contrast($primary-800);
  width: 100%;
  max-width: calc(100% - 60px);
  position: absolute;
  z-index: 10000;
  overflow: hidden;
  top: 56px;
  transform: translateY(50px) !important;

  .list-item {
    color: $text-primary;
    transition: background 0.3s;
    cursor: pointer;

    p {
      height: 48px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: $spacing-2;
      max-width: 250px;
    }

    &:hover {
      @include background-with-contrast($secondary-200);
    }
  }
}
