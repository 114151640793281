@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  padding: $spacing-4;

  &__card {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $spacing-10;
    padding: $spacing-6;
    background-color: $background-paper;
    border-radius: $border-sm;

    &__row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: $spacing-10;

      @include media-tablet {
        flex-direction: column;
      }
    }

    &__column {
      display: flex;
      gap: $spacing-10;

      @include media-tablet {
        flex-direction: column;
      }
    }

    &__menu {
      &__wrapper {
        width: 100%;
        max-width: 450px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;
        gap: $spacing-4;
      }

      &__divider {
        width: 15px;
        height: 1px;
        background-color: $text-secondary;
        transform: rotate(-45deg);
      }

      &__item {
        font-size: 14px;
        font-weight: 700;
        color: $text-secondary;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: $text-primary;
        }
      }
    }

    &__phone {
      &__container {
        display: flex;
        flex-direction: column;
        gap: $spacing-4;
      }

      &__label {
        font-size: 14px;
        color: $text-secondary;
        font-weight: 600;
      }

      &__number {
        font-size: 28px;
        color: $text-primary;
      }
    }

    &__button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid $text-secondary;
      display: flex;
      align-items: center;
      justify-content: center;

      &__icon {
        width: 25px;
        height: 25px;
        color: $text-secondary;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: $text-primary;
        }
      }
    }
  }
}

.text {
  display: flex;
  gap: $spacing-1;
  font-size: 0.8rem;
}

.inner-card {
  width: 100%;
  border-radius: 8px;
  padding: 64px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background: rgb(213, 213, 218);
  background: linear-gradient(
    90deg,
    rgba(213, 213, 218, 1) 0%,
    rgba(232, 232, 232, 1) 100%
  );
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  justify-content: space-between;

  &__icon {
    width: 25px;
    height: 25px;
    transform: rotate(-45deg);
  }

  @include dark-mode {
    background: rgb(29, 29, 29);
    background: linear-gradient(
      90deg,
      rgba(29, 29, 29, 1) 0%,
      rgba(60, 60, 60, 1) 100%
    );
  }
}
