@import "~@/styles/theme/main.scss";
.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 1500px;
    border-radius: $border-sm;
    margin-bottom: 80px;
    padding-top: $spacing-3;
    padding-block: $spacing-8;
    padding: $spacing-8;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: auto auto 1fr;
    gap: $spacing-8;
    margin-inline: auto;

    &--customDetails {
      margin-top: -80px;
    }

    @include media-tablet {
      padding-block: $spacing-0;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      margin-top: unset;
      margin-inline: 0;
    }

    @include media-phone {
      padding-block: $spacing-0;
    }
  }

  .wrapper-pc {
    width: 100%;
    max-width: 1020px;
    border-radius: $border-sm;
    margin-bottom: 80px;
    padding-top: $spacing-3;
    padding-block: $spacing-8;
    padding: $spacing-8;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: auto auto 1fr;
    gap: $spacing-8;
    margin-inline: auto;

    &--customDetails {
      margin-top: -80px;
    }

    @include media-tablet {
      padding-block: $spacing-0;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      margin-top: unset;
      margin-inline: 0;
    }

    @include media-phone {
      padding-block: $spacing-0;
    }
  }

  .details {
    display: grid;
    grid-column: span 2;
    padding-right: $spacing-8;

    @include media-tablet {
      padding-right: $spacing-0;
    }

    & h1 {
      font-size: 24px;
      font-weight: 600;

      & span {
        color: $primary-600;

        @include dark-mode {
          color: $primary-200;
        }
      }
    }

    & p {
      font-size: 18px;
      color: $text-secondary;
    }
  }

  .details-pc {
    display: grid;
    grid-column: span 3;
    padding-right: $spacing-8;

    @include media-tablet {
      padding-right: $spacing-0;
    }

    & h1 {
      font-size: 24px;
      font-weight: 600;

      & span {
        color: $primary-600;

        @include dark-mode {
          color: $primary-200;
        }
      }
    }

    & p {
      font-size: 18px;
      color: $text-secondary;
    }
  }

  .details-wrapper {
    @include default-shape;

    width: 100%;
    display: grid;
    grid-column: span 2;
    border-radius: $border-sm;
    // margin-top: 80px;
    padding: 20px;

    &--customDetails {
      margin-top: unset;
      box-shadow: $box-shadow-md;
      background-color: $background-default;
      padding: $spacing-6;
    }

    @include media-tablet {
      background-color: unset;
      box-shadow: unset;
    }
  }
  .details-wrapper-pc {
    @include default-shape;

    width: 100%;
    display: grid;
    grid-column: span 3;
    border-radius: $border-sm;
    // margin-top: 80px;
    padding: 20px;

    &--customDetails {
      margin-top: unset;
      box-shadow: $box-shadow-md;
      background-color: $background-default;
      padding: $spacing-6;
    }

    @include media-tablet {
      background-color: unset;
      box-shadow: unset;
    }
  }

  .form-wrapper {
    @include default-shape;

    width: 100%;
    display: grid;
    grid-column: span 1;
    height: max-content;
    position: sticky;
    top: 80px;
    left: 0;
    border-radius: $border-sm;
    // margin-top: 80px;
    background-color: $background-default;
    padding: $spacing-6;
    box-shadow: $box-shadow-md;

    &--customDetails {
      margin-top: unset;
    }
  }

  .form-wrapper-pc {
    @include default-shape;

    width: 100%;
    display: grid;
    grid-column: span 3;
    height: max-content;
    position: sticky;
    top: 80px;
    left: 0;
    border-radius: $border-sm;
    // margin-top: 80px;
    background-color: $background-default;
    padding: $spacing-6;
    box-shadow: $box-shadow-md;

    &--customDetails {
      margin-top: unset;
    }
  }

  .information {
    padding-top: $spacing-6;
    display: grid;
    grid-column: span 2;
    grid-column-start: 1;
    padding-block: $spacing-10;

    & h2 {
      font-weight: 700;
      font-size: 22px;
    }

    dl {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      row-gap: $spacing-4;
      column-gap: $spacing-8;
      padding-block: $spacing-4;
      padding-bottom: $spacing-8;

      @include media-tablet {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .info-wrapper {
        display: flex;
        flex-direction: column;
        gap: $spacing-2;

        dt {
          font-size: 18px;
          font-weight: 400;
          color: $text-secondary;
        }

        dd {
          margin-top: $spacing-1;
          font-weight: 700;
          color: $primary-700;

          @include dark-mode {
            color: $primary-200;
          }
        }

        .dd-variant {
          font-size: 16px;
          margin-top: $spacing-1;
          font-weight: 400;
          color: $text-secondary;
        }
      }
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: $text-secondary;
  }

  .about {
    margin-top: $spacing-0;
    margin-bottom: $spacing-4;

    & h2 {
      font-weight: 700;
      font-size: 22px;
    }

    & p {
      font-size: 16px;
    }
  }

  .form-container {
    position: relative;

    .price {
      font-size: 20px;
      font-weight: 700;
    }

    .contact {
      font-size: 16px;
      font-weight: 500;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: $spacing-4;
      padding-block: $spacing-4;

      & label {
        display: block;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: $spacing-1-5;
      }

      & input,
      textarea {
        width: 100%;
        background-color: $background-paper;
        height: 56px;
        color: $text-primary;
        font-size: 14px;
        border-radius: $border-sm;
        transition: all 0.3s;
        padding-inline: $spacing-4;
        resize: none;

        &:focus,
        &:hover {
          background-color: $background-paper;
          outline: none;
        }
      }

      button {
        @include default-shape;
        @include background-with-contrast($primary-500);

        width: 100%;
        padding-block: $spacing-2;
        padding-inline: $spacing-4;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-sm;
        transition: all 0.3s;

        & .dark {
          @include background-with-contrast($primary-700);
        }

        &:hover {
          @include background-with-contrast($primary-700);
        }
      }
    }
  }

  .form-actions {
    grid-row: span 3;

    @include media-tablet {
      margin-top: $spacing-4;
    }

    .vehicle-price {
      font-size: 18px;
      font-weight: 700;
    }

    .send-message-container {
      width: 100%;
      margin-top: $spacing-5;

      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .actions-container {
        display: flex;
        flex-direction: column;
        padding-block: $spacing-5;
      }
    }
  }

  .action-button {
    @include default-shape;

    cursor: pointer;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-inline: $spacing-6;
    padding-block: $spacing-3;
    border-radius: $border-sm;
    @include background-with-contrast($primary-50);
    transition: all 0.3s;
    box-shadow: $box-shadow-sm;
    font-size: 16px;
    font-weight: 500;
    margin-top: $spacing-2;

    @include dark-mode {
      @include background-with-contrast($primary-400);

      &:hover {
        @include background-with-contrast($primary-600);
      }
    }

    &:hover {
      @include background-with-contrast($primary-600);
      box-shadow: $box-shadow-md;
    }

    .icon {
      width: 30px;
      height: 30px;
      margin-right: $spacing-2;
    }

    &--darken {
      @include background-with-contrast($primary-700);

      &:hover {
        @include background-with-contrast($primary-600);
      }

      @include dark-mode {
        @include background-with-contrast($primary-700);
      }
    }
  }
}
