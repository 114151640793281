@import "~@/styles/theme/main.scss";
.container {
  position: fixed;
  transition: all 0.3s;
  padding-block: $spacing-5;
  color: white;
  background-color: $secondary-500;
  // height: $spacing-24;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  box-shadow: 1px 10px 40px rgb(0 0 0 / 65%);
  z-index: 100;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &--3cols {
      grid-template-columns: repeat(3, 1fr);

      @include media-phone {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .wrapper {
    border-right: 2px solid black;
    position: relative;
    color: black;
    height: $spacing-14;

    & a {
      gap: $spacing-8;

      .contact {
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        margin-top: $spacing-2;

        & .icon {
          margin-top: 0;
        }

        & p {
          font-weight: 800;
          text-transform: uppercase;
        }
      }
    }

    &--hide {
      @include media-phone {
        display: none;
      }
    }
  }
}
