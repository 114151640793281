@import "~@/styles/theme/main.scss";
.whatsapp__form__container {
  width: 100%;
  height: 100vh;
  margin-top: -10px;
  border-radius: 14px;
  // padding-top: 20px;

  .header__container {
    width: 100%;
    position: relative;

    h4 {
      padding-left: 20px;
      font-size: 1rem;
      font-weight: bold;
    }

    button {
      position: absolute;
      right: 5px;
      top: -10px;
      cursor: pointer;
    }
  }

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 40px 20px 50px 20px;

    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      input {
        width: 100%;
        height: 3rem;
        padding-left: 10px;

        border-radius: 10px;
        outline: 2px solid #80808030;

        &:focus {
          outline: 2px solid grey;
        }
      }

      label {
        font-size: 12px;
        font-weight: 500;
        color: #80808080;
      }

      input[type="checkbox"] {
        opacity: 0;
        width: 15px;
        height: 15px;
        outline: none;
      }
    }

    textarea {
      width: 100%;
      outline: 2px solid #80808030;
      border-radius: 10px;
      padding-left: 10px;
      padding-top: 10px;
      resize: none;

      &:focus {
        outline: 2px solid grey;
      }
    }

    .footer__container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;

      button {
        width: 100%;
        @include default-shape;
        @include background-with-contrast($primary-500);
        padding-block: $spacing-3;
        padding-inline: $spacing-5;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $spacing-6;
        font-size: 18px;
        font-weight: bold;
        transition: all 0.3s;

        &:hover {
          @include background-with-contrast($primary-700);
        }

        .icon__loading {
          cursor: pointer;
          animation: spin 1s infinite linear;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @include media-tablet {
          font-size: 12px;
          gap: $spacing-4;
        }
      }
    }
  }
}

.button {
  @include default-shape;
  @include background-with-contrast($primary-500);
  padding-block: $spacing-3;
  padding-inline: $spacing-5;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: $spacing-6;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s;

  &:hover {
    @include background-with-contrast($primary-700);
  }

  @include media-tablet {
    font-size: 12px;
    gap: $spacing-4;
  }
}
