@import "~@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: $spacing-4;
  flex-wrap: wrap;

  &__button {
    @include background-with-contrast($primary-500);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      @include background-with-contrast($primary-600);
    }
  }
}
