@import "~@/styles/theme/main.scss";
.input {
  @include default-shape;
  width: 100%;
  outline: none;
  background-color: $background-paper;
  color: $text-primary;
  height: 56px;
  padding-inline: $spacing-3;
  font-size: 14px;
}

.label {
  margin-block: $spacing-2;
  min-width: none;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}
