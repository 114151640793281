@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  @include media-tablet {
    padding: $spacing-4;
  }

  .wrapper {
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-block: $spacing-4;
      border-bottom: 2px;
      box-shadow: $box-shadow-sm;

      & h2 {
        font-weight: 600;
        font-size: 24px;
      }
      & h1 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 24px;
      }
    }
  }

  .order-wrapper {
    display: flex;
    align-items: center;
    gap: $spacing-3;
  }

  .mobile-icon {
    display: none;

    @include media-tablet {
      display: flex;
    }
  }

  .filter-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $spacing-8;

    @include media-tablet {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .filter-content {
    display: block;
    margin-top: $spacing-2;
    overflow: auto;

    @include media-tablet {
      display: none;
      max-height: unset;
    }

    &--hidden {
      display: hidden !important;
    }

    &::-webkit-scrollbar {
      background-color: $background-default;
      width: 8px;
      border-radius: 8px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-track {
      background-color: $background-default;
      border-left: unset;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-500;
      border-radius: 16px;
      border: 4px solid $primary-500;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}
