@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  padding: $spacing-10;
  padding-block: $spacing-10;
  margin: 0 auto;
  max-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-4;
  min-height: 80vh;

  @include media-tablet {
    flex-direction: column;
    align-items: center;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    align-items: flex-start;

    @include media-tablet {
      align-items: center;
    }

    &__title {
      color: $text-primary;
      font-size: 60px;
      font-weight: 600;

      @include media-tablet {
        font-size: 32px;
      }
    }

    &__description {
      color: text-secondary;
      font-size: 18px;
      font-weight: 400;

      @include media-tablet {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &__row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $spacing-10;

      @include media-tablet {
        justify-content: center;
      }
    }

    &__image {
      @include media-tablet {
        display: none;
      }

      &__container {
        @include default-shape;
        width: 100%;
        display: grid;
        grid-gap: $spacing-10;
        grid-template:
          "card1 card2 card3" 200px
          "card1 card4 card3" 200px
          "card5 card4 card3" 200px
          / 1fr 1fr 1fr;
        height: 100%;
        overflow: hidden;
      }

      &__item {
        @include default-shape;
        width: 100%;
        overflow: hidden;
        height: 100%;
      }
    }
  }
}
