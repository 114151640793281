@import "~@/styles/theme/main.scss";
.container {
  display: flex;
  flex-direction: column;

  .disclosure {
    width: 100%;
    transition: height 0.3s, padding 0.3s;
    border-bottom: 1px;
    border-color: $primary-500;
    padding-block: 0px !important;
    padding-inline: $spacing-4;
    border: $border-default;
    padding-top: $spacing-6;
    display: grid;
    gap: 0px;
    overflow: hidden;

    &--closed {
      height: 0px !important;
      padding-block: 0;
    }
  }

  & .button-container {
    margin-inline: -$spacing-2;
    margin-block: -$spacing-3;
    display: flow-root;
    cursor: pointer;
  }

  & .button-row {
    padding-inline: $spacing-2;
    padding-block: $spacing-3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      font-size: 16px;
      font-weight: 600;
      color: $text-primary;
    }

    & .button-icon {
      margin-left: -$spacing-6;
      display: flex;
      align-items: center;
      transition: all 0.3s;

      &--open {
        transform: rotate(180deg);
      }
    }
  }
}
