@import "~@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;
  gap: $spacing-4;

  &__address {
    display: flex;
    flex-direction: column;
    gap: $spacing-2;
    align-items: flex-start;

    &__label {
      &__decorator {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid $primary-500;
      }

      display: flex;
      align-items: center;
      gap: $spacing-4;
      font-size: 12px;
      color: $text-primary;
      text-transform: uppercase;
    }

    &__item {
      font-size: 12px;
      font-weight: 700;
      color: $text-secondary;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: $primary-500;
      }
    }
  }
}
