@import "~@/styles/theme/main.scss";
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(134, 130, 130, 0.5);
  backdrop-filter: blur(2px);
  z-index: 9999;

  .modal {
    width: 35%;
    margin: 0 auto;
    background-color: white;
    border-radius: $border-radius;
    padding: 30px 30px;

    .modal__header {
      font-size: 1rem;
      font-weight: 600;
      position: relative;

      .icon {
        position: absolute;
        top: -20px;
        right: -10px;
        cursor: pointer;
      }
    }

    form {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      input {
        width: 100%;
        height: 2.5rem;
        padding-left: 10px;

        border-radius: 10px;
        outline: 2px solid #80808030;

        &:focus {
          outline: 2px solid grey;
        }
      }

      textarea {
        width: 100%;
        outline: 2px solid #80808030;
        border-radius: 10px;
        padding-left: 10px;
        padding-top: 10px;
        resize: none;

        &:focus {
          outline: 2px solid grey;
        }
      }

      button {
        width: 100%;
        @include default-shape;
        @include background-with-contrast($primary-500);
        padding-block: $spacing-3;
        padding-inline: $spacing-5;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $spacing-6;
        font-size: 18px;
        font-weight: bold;
        transition: all 0.3s;
        border-radius: 10px !important;

        &:hover {
          @include background-with-contrast($primary-700);
        }

        .icon__loading {
          cursor: pointer;
          animation: spin 1s infinite linear;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @include media-tablet {
          font-size: 12px;
          gap: $spacing-4;
        }
      }
    }
  }
}
