@import "~@/styles/theme/main.scss";
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(134, 130, 130, 0.5);
  backdrop-filter: blur(2px);
  z-index: 9999;

  .menu-container {
    width: 100%;
    min-height: 25%;
    position: fixed;
    bottom: 0;
    border-radius: 10px 10px 0px 0px;

    background-color: white;

    .menu-header {
      width: 100%;
      position: relative;
      margin-bottom: 10px;
      display: flex;
      padding: 20px 10px 0px 10px;

      .wrapper {
        display: flex;
        align-items: center;
        gap: 20px;

        .header-content {
          display: flex;
          flex-direction: column;

          .header-vendor-name {
            font-size: 16px;
            font-weight: 600;
          }

          .short-description {
            font-size: 12px;
            font-weight: 500;
            margin-top: -3px;
          }
        }

        .image-wrapper {
          position: relative;
          // border: 1px solid $primary-400;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          border-radius: 100%;
          overflow: hidden;
        }

        .image {
          width: 100%;
          max-width: 80px;
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;

          @include media-tablet {
            max-width: 50px;
          }
        }
      }

      .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }

    .separator {
      width: 150%;
      height: 1px;
      background-color: #80808030;
    }
  }

  .container-actions {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    .button {
      width: 100% !important;
      @include default-shape;
      @include background-with-contrast($primary-500);
      padding-block: $spacing-3;
      padding-inline: $spacing-5;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: $spacing-6;
      font-size: 18px;
      font-weight: bold;
      transition: all 0.3s;

      &:hover {
        @include background-with-contrast($primary-700);
      }

      @include media-tablet {
        font-size: 12px;
        gap: $spacing-4;
      }
    }
  }
}
