@import "~@/styles/theme/main.scss";
.card {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  border: 1px solid #ccc;
  grid-column: span 3;

  @include media-tablet {
    flex-direction: column;
    grid-column: span 2;
    height: max-content;
    max-height: unset;
  }

  @include media-phone {
    height: max-content;
    max-height: unset;
  }

  .image {
    width: 100%;
    max-width: 35%;
    height: 100% !important;
    object-fit: cover;
    background-position: center;
    position: relative;
    margin: 0;

    @include media-tablet {
      max-width: unset;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    gap: $spacing-1;
    display: flex;
    flex-direction: column;

    & h3 {
      font-size: 22px;
      font-weight: 600;
      line-height: 1rem;

      & .highlight {
        line-height: 1.75rem;
        color: $primary-500;
        font-weight: 700;
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;

      .description {
        margin-top: -1px;
        font-size: 10px;
        color: $text-secondary;
      }

      & h4 {
        font-size: 20px;
        font-weight: 600;

        & .price {
          color: $primary-500;

          @include dark-mode {
            color: $primary-200;
          }
        }
      }
    }

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $spacing-3;
      margin-top: $spacing-3;

      & p {
        width: max-content;
        font-size: 13px;
        color: black;
        font-weight: 700;

        @include dark-mode {
          color: white !important;
        }
      }
    }
  }

  .chip_row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-1;
    padding-block: $spacing-1;
  }
}
