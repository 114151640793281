@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: $spacing-1;
  min-height: 50px;

  .chip {
    @include default-shape;
    margin: $spacing-1;
    display: inline-flex;
    width: fit-content;
    box-shadow: $box-shadow-sm;
    align-items: center;
    justify-content: center;
    padding-block: $spacing-1-5;
    padding-inline: $spacing-3;
    font-size: 14px;
    font-weight: 500;
    color: #444;
    border: 1px solid #666;
    background-color: $background-default;
    background: $primary-100;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: $box-shadow-lg;
    }

    .button {
      flex-shrink: 0;
      margin-left: $spacing-1;
      height: $spacing-4;
      width: $spacing-4;
      padding: $spacing-1;
      border-radius: $border-sm;
      display: inline-flex;
      transition: color 0.3s;

      &:hover {
        color: $primary-500;
      }
    }

    svg {
      width: $spacing-2;
      height: $spacing-2;
    }
  }
}
