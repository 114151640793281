@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: $spacing-2;
  align-items: center;
  justify-content: center;
  padding: $spacing-2;

  h1 {
    font-size: 36px;
    font-weight: 600;
    color: white;
    text-align: center;
  }

  .wrapper {
    width: 100%;
    max-width: 400px;
    position: relative;

    .input {
      background-color: $background-default;
      color: $text-primary;
      width: 100%;
      height: 56px;
      border-radius: $border-sm;
      padding-left: $spacing-3;
      outline: none;
      margin-inline: auto;

      @include default-shape;

      :focus {
        outline: none;
      }
    }

    .button {
      @include background-with-contrast($secondary-500);
      position: absolute;
      right: 0;
      top: 0;
      width: 56px;
      height: 56px;
      border-radius: $border-sm;
      display: flex;
      align-items: center;
      justify-content: center;

      @include default-shape;
    }

    .list-container {
      background-color: $background-paper;
      width: 100%;
      position: absolute;
      z-index: 10000;
      border-bottom-right-radius: $border-sm;
      border-bottom-left-radius: $border-sm;
      overflow: hidden;

      .list-item {
        color: $text-primary;
        transition: background 0.3s;
        cursor: pointer;

        p {
          height: 48px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: $spacing-2;
        }

        &:hover {
          @include background-with-contrast($secondary-200);
        }
      }
    }

    @include media-phone {
      max-width: 90vh;
    }
  }
}
