@import "~@/styles/theme/main.scss";
.chip {
  @include default-shape;

  padding-inline: $spacing-4;
  padding-block: $spacing-2;
  background-color: $background-default;
  font-size: 14px;
  height: max-content;
  font-weight: 700;
  text-wrap: nowrap;

  &--text-only {
    padding-inline: unset;
    padding-block: unset;
    font-weight: 400;
    background-color: $primary-50;
    color: black;
  }

  &--stroke {
    border: 1px solid $background-default;
  }

  &--paper {
    background-color: $background-paper;
  }

  &--small {
    font-size: 12px;
    padding-inline: $spacing-2;
    padding-block: $spacing-1;
  }

  @include media-tablet {
    font-size: 12px;
  }
}
