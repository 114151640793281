@import "~@/styles/theme/main.scss";
.section {
  padding-block: $spacing-10;
  background-color: $background-paper;
  margin-top: 2rem;
  padding: 40px 0px !important;

  .container {
    width: 90%;
    padding: 0px 10px;
    max-width: 1500px;
    margin: 0 auto;

    .content__wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 20px;
      justify-content: space-between;
      max-height: 510px;
      overflow: hidden;
      transition: max-height 0.5s ease-in-out, padding-bottom 0.5s ease-in-out;

      .column {
        width: calc(100% / 6);
        min-width: 200px;

        @include media-phone {
          margin-bottom: 40px;
          display: flex;
          flex-direction: column;
          justify-content: start;
        }
      }

      h2 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 1rem;
        align-items: center;
      }

      .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        .list__item {
          font-size: 13px;

          &:hover {
            color: $primary-500;
            text-decoration: underline;
          }
        }
      }

      @include media-phone {
        justify-content: center;
      }
    }

    .toggleCheckbox:checked ~ .content__wrapper {
      max-height: 9999px;
      padding-bottom: 20px;
    }

    .toggleCheckbox {
      display: none;
    }

    .showMoreButton {
      cursor: pointer;
      background-color: $primary-500;
      color: $background-paper;
      border: none;
      padding: 10px 15px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 5px;
      text-align: center;
      margin-top: 20px;
      transition: background-color 0.3s ease-in-out;
    }

    .showMoreButton:hover {
      background-color: $primary-600;
    }

    @include media-phone {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  @include media-x-large-desktop {
    padding: 0px;
  }

  @include media-phone {
    padding: 5px 10px;
  }

  @include media-tablet {
    padding: 0px 20px;
  }
}
