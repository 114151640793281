@import "~@/styles/theme/main.scss";
.form-container {
  position: relative;
  width: 100%;

  .price {
    font-size: 20px;
    font-weight: 700;
  }

  .contact {
    font-size: 16px;
    font-weight: 500;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;
    padding-block: $spacing-4;

    & label {
      display: block;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: $spacing-1-5;
    }

    & input,
    textarea {
      width: 100%;
      background-color: $background-paper;
      height: 56px;
      color: $text-primary;
      font-size: 14px;
      border-radius: $border-sm;
      transition: all 0.3s;
      padding-inline: $spacing-4;
      resize: none;

      &:focus,
      &:hover {
        background-color: $background-paper;
        outline: none;
      }
    }

    textarea {
      padding-top: 10px;
      height: unset;
    }

    button {
      @include default-shape;
      @include background-with-contrast($primary-500);
      padding-block: $spacing-2;
      padding-inline: $spacing-4;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $spacing-2;
      font-weight: bold;
      transition: all 0.3s;

      & .dark {
        @include background-with-contrast($primary-700);
      }

      .icon__loading {
        cursor: pointer;
        animation: spin 1s infinite linear;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      &:hover {
        @include background-with-contrast($primary-700);
      }
    }
  }
}

.form-actions {
  grid-row: span 3;

  @include media-tablet {
    margin-top: $spacing-4;
  }

  .vehicle-price {
    font-size: 18px;
    font-weight: 700;
  }

  .send-message-container {
    width: 100%;
    margin-top: $spacing-5;

    .title {
      font-size: 16px;
      font-weight: 500;
    }

    .actions-container {
      display: flex;
      flex-direction: column;
      padding-block: $spacing-5;
    }
  }
}
