@import "~@/styles/theme/main.scss";
.container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include background-with-contrast($primary-900);

  .wrapper {
    width: 100%;
    max-width: 1500px;
    display: flex;
    margin-inline: auto;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));

    @include media-tablet {
      display: flex;
      flex-direction: column;
    }

    .content {
      padding-block: $spacing-24;
      padding-inline: $spacing-12;
      display: grid;
      grid-column: span 2;

      @include media-phone {
        padding-block: $spacing-10;
        padding-inline: $spacing-3;
      }

      .content-wrapper {
        margin-inline: auto;
      }
    }
  }

  .item {
    margin-top: $spacing-8;

    &__wrapper {
      display: flex;
      align-items: center;

      &__icon {
        width: $spacing-5;
        height: $spacing-5;
        display: inline;
        margin-right: $spacing-3;
      }

      &--spaced {
        margin-top: $spacing-2;
      }
    }
  }

  .form {
    padding-block: $spacing-24;
    padding-inline: $spacing-4;
    padding-left: $spacing-12;
    display: grid;
    grid-column: span 3;

    @include media-tablet {
      padding-left: unset;
      padding-block: $spacing-16;
      padding-inline: $spacing-4;
    }

    &__wrapper {
      margin-inline: auto;
      padding: $spacing-4;
      min-width: 320px;

      &__content {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: $spacing-3;
        padding-top: $spacing-16;

        @include media-tablet {
          padding-top: unset;
        }
      }
    }

    &__item {
      position: relative;
    }

    &__error-text {
      color: red;
      font-weight: 600;
      font-size: 14px;
    }

    &__button {
      @include background-with-contrast($primary-200);
      color: $primary-900;
      width: $spacing-64;
      height: $spacing-10;
      font-weight: 600;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: $box-shadow-sm;

      &:hover {
        @include background-with-contrast($primary-400);
        box-shadow: $box-shadow-lg;
      }
    }
  }
}

.title-wrapper {
  width: 100%;
  position: relative;
  text-align: center;
  color: $primary-300;
  font-weight: 700;

  .title {
    position: relative;
    width: max-content;
    font-size: 2rem;

    .text-decorator {
      width: 100%;
      position: absolute;
      bottom: $spacing-1;
      right: 0;
      transform: translateY(100%) rotate($spacing-3);
      height: auto;
      fill: $primary-300;
      stroke: currentColor;
    }
  }
}

.variant2 {
  &__container {
    position: relative;
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    display: grid;
    place-items: center;
    width: 100%;
  }

  &__neutral-bg {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    opacity: 50%;
    background-color: $background-paper;
    position: relative;
  }

  &__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    padding-inline: $spacing-60;
    gap: $spacing-32;
    flex-wrap: nowrap;
    flex-direction: row;

    @include media-tablet {
      flex-wrap: wrap;
      padding-inline: $spacing-10;
      gap: $spacing-10;
    }
  }

  &__form {
    width: 80%;
    max-height: 50vh;
    margin-left: $spacing-10;
    min-width: 310px;

    &__title {
      margin-bottom: $spacing-5;
      font-size: 36px;
      font-weight: 700;
      color: "text-gray-800";
    }

    &__card {
      @include default-shape;

      background-color: $background-default !important;
      width: $spacing-96;
      height: max-content;
      min-width: 350px;
      display: flex;
      justify-content: center;
      padding: $spacing-6;

      &__content {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: $spacing-3;
      }

      &__error-text {
        color: red;
        font-weight: 600;
        font-size: 14px;
      }

      &__label {
        margin-block: $spacing-2;
        min-width: none;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
      }

      &__button {
        @include default-shape;
        @include background-with-contrast($primary-400);

        margin-top: $spacing-6;
        width: 100%;
        height: 56px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        border-radius: $border-sm;
        transition: background 0.3s;

        &:hover {
          @include background-with-contrast($primary-600);
        }
      }

      &__textarea {
        @include default-shape;
        resize: none;
        width: 100%;
        outline: none;
        background-color: $background-paper;
        height: 80px;
        padding-inline: $spacing-3;
        font-size: 14px;
        padding-top: 5px;
      }
    }
  }
}
