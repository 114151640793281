@import "~@/styles/theme/main.scss";
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 55;

  .container {
    background-color: $background-default;
    height: auto;
    position: relative;
    max-width: 85%;
    max-height: 85%;

    .icon {
      position: absolute;
      top: -30px;
      right: -30px;
      cursor: pointer;

      @include media-phone {
        right: -25px;
      }
    }

    @include media-x-large-desktop {
      padding: 0px;
    }

    @include media-phone {
      padding: 5px 10px;
    }

    @include media-tablet {
      padding: 0px 20px;
    }
  }
}
