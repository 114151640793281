@import "~@/styles/theme/main.scss";
.container {
  @include default-shape;

  padding-block: $spacing-3;
  margin-top: $spacing-8;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  .wrapper {
    display: flex;
    flex: 1;

    @include media-phone {
      justify-content: flex-end;
    }

    .button {
      @include default-shape;

      position: relative;
      display: inline-flex;
      align-items: center;
      padding-inline: $spacing-4;
      padding-block: $spacing-2;
      cursor: pointer;
      // @include background-with-contrast($primary-50);
      transition: all 0.3s;
      font-size: 0.8rem;
      font-weight: 500;
      border: 1px solid #444;

      @include dark-mode {
        @include background-with-contrast($primary-700);

        &:hover {
          @include background-with-contrast($primary-600);
        }
      }

      &:hover {
        @include background-with-contrast($primary-200);
      }
    }

    .button-next {
      margin-left: auto;
    }

    .button-prev {
      margin-right: auto;
    }
  }

  .wrapper.space-between {
    justify-content: space-between;
  }
}
