@import "~@/styles/theme/main.scss";
.container {
  position: relative;
  display: inline-block;
  text-align: left;

  .button {
    @include default-shape;

    display: flex;
    align-items: center;
    gap: $spacing-4;
    // padding-block: $spacing-2;
    // padding-inline: $spacing-4;
    justify-content: center;
    background-color: $background-default;
    font-size: 0.8rem;
    font-weight: 600;
    // box-shadow: $box-shadow-sm;
    cursor: pointer;

    // &:hover {
    //   box-shadow: $box-shadow-lg;
    // }

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  .order-container {
    @include default-shape;

    width: 320px;
    z-index: 40;
    height: max-content;
    max-height: 400px;
    overflow-y: auto;
    background-color: $background-paper;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + $spacing-2);
    right: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    .item {
      width: 100%;
      height: 40px;
      padding-block: $spacing-2;
      padding-inline: $spacing-4;
      background-color: $background-default;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;

      &--selected {
        @include background-with-contrast($primary-500);
        font-weight: 600;
      }

      &:hover {
        @include background-with-contrast($primary-500);
      }
    }
  }
}
