@import "~@/styles/theme/main.scss";
.wrapper-item {
  position: relative;

  .container {
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow-x: hidden;

    .slider {
      width: 100% !important;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .child-wrapper {
        min-height: 300px;
        padding: 20px;
        width: 100% !important;
        min-width: 100% !important;
        display: grid;
        align-items: center;
        height: 100% !important;
        gap: $spacing-5;
        grid-template-columns: repeat(2, 1fr);

        &--4col {
          grid-template-columns: repeat(4, 1fr) !important;
        }

        @include media-phone {
          grid-template-columns: repeat(1, 1fr) !important;
          padding: 5px;
          display: flex;
        }

        @include media-tablet {
          grid-template-columns: repeat(2, 1fr) !important;
          padding: 5px;
          display: flex;
        }

        @include media-large-desktop {
          grid-template-columns: repeat(3, 1fr) !important;
        }
      }

      .child {
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }
  }

  .button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 4;
    transition: all 0.3s;
    border-radius: 20px;
    @include background-with-contrast($primary-500);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &--left {
      left: -16px;
    }

    &--right {
      right: -16px;
    }

    &:hover {
      @include background-with-contrast($primary-700);
    }
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  &:hover {
    .button {
      opacity: 1;
    }
  }
}
