@import "~@/styles/theme/main.scss";
.button {
  all: unset;

  @include default-shape;

  background-color: $primary-500;
  width: max-content;
  display: flex;
  align-items: center;
  padding-inline: $spacing-4;
  padding-block: $spacing-2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-4;
  cursor: pointer;
  pointer-events: all;
  transition: all 0.3s;
  color: white;

  &__icon {
    width: 25px;
    height: 25px;
    // @include background-with-contrast($primary-500);

    @include dark-mode {
      // @include background-with-contrast($primary-300);
    }
  }

  @include dark-mode {
    // @include background-with-contrast($primary-300);
  }
}
