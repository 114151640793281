@import "~@/styles/theme/main.scss";
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .wrapper {
    display: flex;
    align-items: center;
    gap: 0px;

    & input {
      @include default-shape;
      appearance: none;
      border-radius: 3px !important;
      // border: $border-default;
      border: 1px solid #606060;
      border-color: $primary-300;
      width: $spacing-3;
      height: $spacing-3;
      background-color: $primary-100;
      transition: background 0.3s;
      cursor: pointer;

      &:checked {
        background-color: $primary-600;
      }

      @include media-phone {
        width: 17px;
        height: 17px;
      }
    }
  }

  @include media-phone {
    gap: 8px;
  }
}
