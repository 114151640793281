@import "~@/styles/theme/main.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__menu {
    &__wrapper {
      width: 100%;
      max-width: 450px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;
      gap: $spacing-4;
    }

    &__divider {
      width: 15px;
      height: 1px;
      background-color: $text-secondary;
      transform: rotate(-45deg);
    }

    &__item {
      font-size: 14px;
      font-weight: 700;
      color: $text-secondary;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: $text-primary;
      }
    }
  }
}
