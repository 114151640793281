@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-inline: auto;
  padding: $spacing-10;
  margin-top: $spacing-8;

  .wrapper {
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-12;

    &--invert {
      flex-direction: row-reverse;
    }

    .menu-icon {
      cursor: pointer;
      display: none;

      @include media-tablet {
        display: unset;
      }
    }

    @include media-tablet {
      align-items: center;
      margin-inline: auto;
      justify-content: space-between;
      gap: $spacing-3;
      width: 90%;
      margin: 0 auto;
    }
  }

  .text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    gap: $spacing-4;
    position: relative;

    h2 {
      color: $primary-800;
      font-size: 36px;
      line-height: 38px;
      font-weight: 700;

      &::before {
        content: attr(data-highlight);
        font-size: 18px;
        position: absolute;
        top: -45px;
        left: -30px;
        color: $primary-700;
        transform: rotate(-30deg);

        @include dark-mode {
          color: $primary-200;
        }

        @include media-tablet {
          display: none;
        }
      }

      @include media-tablet {
        font-size: 16px;
        line-height: 18px;
      }

      @include media-tablet {
        position: absolute;
        top: 100px;
        left: -60px;
      }

      @include media-phone {
        top: 90px;
      }
    }

    .description {
      font-size: 18px;
      color: $text-secondary;

      @include media-tablet {
        font-size: 11px;
        display: none;
      }

      @include media-tablet {
        position: absolute;
        top: 120px;
        left: -60px;
      }

      @include media-phone {
        top: 110px;
      }
    }

    @include media-tablet {
      font-size: 14px;
      line-height: 16px;
      gap: $spacing-2;
      display: flex;
      flex-direction: row-reverse;

      h2 {
        display: none;
      }
    }
  }

  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    .vendor-name-container {
      display: none;
      width: max-content;

      h1 {
        font-size: 16px;
        font-weight: 600;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        margin-top: -3px;
        max-width: 240px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        line-height: 14px;
        text-align: start;
      }

      @include media-tablet {
        display: flex;
        flex-direction: column;
        justify-content: start !important;
        align-items: start !important;
        position: absolute;
        left: 70px;
        top: 20%;
      }
    }

    @include media-tablet {
      width: max-content;
      margin-bottom: 0px;
    }

    .image-wrapper {
      position: relative;
      // border: 1px solid $primary-400;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border-radius: 100%;
      overflow: hidden;
    }

    .image {
      width: 100%;
      max-width: 250px;
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center;
      // @include default-shape;

      @include media-tablet {
        max-width: 80px;
      }
    }

    .quote {
      top: -25px;
      left: -25px;
      font-size: 14px;
      position: absolute;
      max-width: 250px;
      color: $text-secondary;
      font-weight: 700;

      @include media-tablet {
        display: none;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    gap: $spacing-4;

    @include media-tablet {
      display: none;
    }
  }

  .button {
    @include default-shape;
    @include background-with-contrast($primary-500);
    padding-block: $spacing-2;
    padding-inline: $spacing-4;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: $spacing-2;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    transition: all 0.3s;

    &:hover {
      @include background-with-contrast($primary-700);
    }

    @include media-tablet {
      font-size: 12px;
      padding-block: $spacing-2;
      padding-inline: $spacing-4;
      gap: $spacing-4;
    }
  }

  @include media-tablet {
    padding: $spacing-2 0px $spacing-2 0px;
    margin-top: 0px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    // margin-bottom: 150px;
  }
}
