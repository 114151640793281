@import "~@/styles/theme/main.scss";
.section {
  padding-block: $spacing-10;

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-4;

    .wrapper {
      width: 100%;
      max-width: 1500px;
      position: relative;
      display: flex;
      flex-direction: column;

      & h2 {
        font-size: 36px;
        font-weight: 600;

        & span {
          font-weight: 700;
          position: relative;
          color: $primary-600;

          @include dark-mode {
            color: $primary-200;
          }
        }
      }

      & p {
        margin-bottom: $spacing-10;
        text-align: left;
      }

      .text-decorator {
        width: 100%;
        position: absolute;
        bottom: $spacing-1;
        right: 0;
        transform: translateY($spacing-1) rotate($spacing-3);
        height: auto;
        stroke: $primary-600;

        @include dark-mode {
          stroke: $primary-200;
        }
      }

      .navigation-wrapper {
        margin-top: $spacing-10;
      }
    }
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $spacing-56;
    background-color: $background-default;
    border-radius: $border-sm;

    @include default-shape;

    &:hover {
      & .image {
        transform: scale(1.2);
      }
    }

    .link {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      border-radius: $border-sm;
      transition: all 0.3s;

      .image {
        transition: all 0.3s;

        &--invert {
          -webkit-filter: invert(100%);
          filter: invert(100%);
        }
      }
    }

    @include media-phone {
      min-width: 300px;
    }
  }
}
