@import "~@/styles/theme/main.scss";
.button {
  @include default-shape;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background-default;
  color: $text-secondary;
  cursor: pointer;
  pointer-events: all;
  transition: all 0.3s;

  &:hover {
    background-color: $background-paper;
    color: $text-primary;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }
}
