@import "~@/styles/theme/main.scss";
.icon {
  width: 80px;
  height: 80px;
  margin-bottom: 2px;
  display: inline-block;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #25d366;
  animation: bounce 2s ease infinite;

  &:hover {
    animation: none;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
}
