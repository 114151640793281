@import "~@/styles/theme/main.scss";
.image-skeleton {
  @include skeleton($height: 208px);
}

.title-skeleton {
  @include skeleton($height: 25px);
}

.description-skeleton {
  @include skeleton($height: 14px);
  max-width: 80%;
  margin-top: $spacing-1;
}

.price-skeleton {
  @include skeleton($height: 18px);
  max-width: 50%;
  margin-top: $spacing-1;
}
