@import "~@/styles/theme/main.scss";
.card {
  @include default-shape;
  padding: 3px;
  background-image: radial-gradient(
      ellipse farthest-corner at right bottom,
      #1c1917 0%,
      #1c1917 8%,
      #1c1917 30%,
      #1c1917 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #1c1917 0%,
      #1c1917 8%,
      #1c1917 25%,
      #1c1917 62.5%,
      #1c1917 100%
    );
  transition: background 2s;

  &:hover {
    padding: 3px;
    background-image: radial-gradient(
        ellipse farthest-corner at right bottom,
        #fedb37 0%,
        #fdb931 8%,
        #9f7928 30%,
        #8a6e2f 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 25%,
        #5d4a1f 62.5%,
        #5d4a1f 100%
      );

    animation: gradientAnimation 2s ease infinite;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  .link {
    @include default-shape;
    width: $spacing-72;
    overflow: hidden;

    .divider {
      width: 80px;
      margin-inline: auto;
      height: 2px;
      color: $text-secondary;
    }
  }

  .armored-container {
    display: block;
    position: relative;

    .text {
      @include default-shape;

      background-color: $background-default;
      padding-inline: $spacing-2;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      top: $spacing-4;
      right: 0;
      z-index: 50;
    }
  }

  .details-wrapper {
    text-align: center;
    padding-block: $spacing-4;
    background-color: $background-default;
    height: $spacing-44;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .make {
      background: -webkit-linear-gradient(
        left,
        #fedb37 0%,
        #fdb931 8%,
        #9f7928 30%,
        #8a6e2f 40%,
        transparent 80%
      );
      background: -webkit-linear-gradient(
        left,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 25%,
        #5d4a1f 62.5%,
        #5d4a1f 100%
      );
      -webkit-background-clip: text;
      color: transparent;
      display: inline-block;
      filter: brightness(1.75);
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
    }

    .model {
      font-weight: 700;
      font-size: 16px;
      color: $text-primary;
    }
  }

  .small-text {
    color: $text-secondary;
    font-size: 16px;
  }
}
