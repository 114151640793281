@import "~@/styles/theme/main.scss";
.information {
  padding-top: $spacing-6;
  display: grid;
  grid-column: span 2;
  grid-column-start: 1;
  padding-block: $spacing-10;

  & h2 {
    font-weight: 700;
    font-size: 22px;
  }

  dl {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: $spacing-4;
    column-gap: $spacing-8;
    padding-block: $spacing-4;
    padding-bottom: $spacing-8;

    @include media-tablet {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .info-wrapper {
      display: flex;
      flex-direction: column;
      gap: $spacing-2;

      dt {
        font-size: 18px;
        font-weight: 400;
        color: $text-secondary;
        display: flex;
        align-items: center;
        gap: 16px;
      }

      dd {
        margin-top: $spacing-1;
        font-weight: 700;
        color: $primary-700;

        @include dark-mode {
          color: $primary-200;
        }
      }

      .dd-variant {
        font-size: 16px;
        margin-top: $spacing-1;
        font-weight: 400;
        color: $text-secondary;
      }
    }
  }
}

.icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  fill: $primary-500;

  & use {
    color: $primary-500;
  }
}
