@import "~@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;

  .input-wrapper {
    position: relative;
    margin-bottom: 0.5rem;

    & input {
      appearance: none;
      width: 40px;
      height: 40px;
      border-radius: 6px;
      border: $border-default;
      border-width: 1px;
      border-color: $primary-300;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-100;
      transition: background 0.3s;
      cursor: pointer;

      @include default-shape;

      border-radius: 8px !important;

      &:checked {
        background-color: $primary-600;
        opacity: 0.6;
      }
    }

    .icon {
      width: auto;
      max-width: 90% !important;
      height: 20px;
      max-height: 25px;
      position: absolute;
      object-fit: fill;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.3s;
      margin-top: -1px;
      pointer-events: none;
      // filter: brightness(0) invert(1);

      @include dark-mode {
        filter: brightness(0);
      }
    }
  }

  label {
    margin-left: $spacing-3;
    min-width: 0;
    display: flex;
    font-size: 16px;
    align-items: center;
    cursor: pointer;

    strong {
      margin-left: $spacing-1;
    }
  }
}
