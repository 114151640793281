@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  @include media-tablet {
    padding: $spacing-4;
  }

  .wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;

    & h1 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 24px;

      @include media-phone {
        font-size: 1.2rem;
      }
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-block: $spacing-4;
      border-bottom: 2px;
      box-shadow: $box-shadow-sm;
    }
  }

  .button {
    @include default-shape;

    display: flex;
    align-items: center;
    gap: $spacing-4;

    justify-content: center;
    background-color: $background-default;
    font-size: 0.8rem;
    font-weight: 600;
    // box-shadow: $box-shadow-sm;
    cursor: pointer;

    // &:hover {
    //   box-shadow: $box-shadow-lg;
    // }

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  .order-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-3;
  }

  .mobile-icon-pc {
    display: flex;

    @include media-tablet {
      display: flex;
    }
  }

  .mobile-icon {
    display: none;

    @include media-tablet {
      display: flex;
    }
  }

  .filter-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $spacing-8;

    @include media-tablet {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .filter-content {
    display: block;
    margin-top: $spacing-2;
    overflow: auto;

    @include media-tablet {
      display: none;
      max-height: unset;
    }

    &--hidden {
      display: hidden !important;
    }

    &::-webkit-scrollbar {
      background-color: $background-default;
      width: 8px;
      border-radius: 8px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-track {
      background-color: $background-default;
      border-left: unset;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-500;
      border-radius: 16px;
      border: 4px solid $primary-500;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}
