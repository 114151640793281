@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .container__image {
    width: 50vw;
    height: 100vh;

    @include media-tablet {
      display: none;
    }
  }

  .container__form {
    width: 50%;
    height: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .form__wrapper {
      width: 80%;
      height: 80%;
      margin: 0 auto;
      padding: 20px 10px;

      .title {
        font-weight: bold;
        font-size: 1.5rem;

        @include media-tablet {
          text-align: center;
        }
      }

      .description {
        font-size: 0.6rem;
        margin-top: $spacing-1;
        font-weight: 500;
      }

      .form {
        .button {
          background-color: $primary-500;
          width: 100%;
          margin-top: $spacing-3;
          border-radius: $border-sm;
          height: 52px;
          font-size: 0.8rem;
          font-weight: bold;
          color: $primary-text-contrast-500;
        }

        .form__error-text {
          font-size: 10px;
          color: red;
          margin-top: -10px;
        }
      }
    }

    @include media-tablet {
      width: 100%;
    }
  }
}
