@import "~@/styles/theme/main.scss";
.container {
  //h-96
  height: 400px;
  box-shadow: $box-shadow-lg;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 700;
    }

    & div {
      text-align: center;
    }
  }
}
