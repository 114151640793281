@import "~@/styles/theme/main.scss";
.container {
  @include default-shape;
  border: none;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-3;

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
    gap: $spacing-3;

    &__heading {
      font-size: 24px;
      font-weight: 600;

      & span {
        color: $primary-600;

        @include dark-mode {
          color: $primary-200;
        }
      }
    }
  }
}
