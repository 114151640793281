@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  background-color: $background-default;
  border-bottom: 1px solid $border-default;
  position: relative;
  z-index: 50;
  padding-block: $spacing-4;
  padding-inline: $spacing-2;

  @include media-phone {
    padding-inline: $spacing-4;
  }

  .wrapper {
    width: 100%;
    max-width: 1500px;
    margin-inline: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &--variant-1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--variant-2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--variant-3 {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include media-tablet {
        justify-content: space-between;
      }
    }

    &--variant-4 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .image-wrapper {
      width: 100%;
      max-width: 250px !important;

      @include media-tablet {
        max-width: max-content !important;
      }

      .image {
        width: max-content;
        align-items: center;
        object-fit: contain;
        max-height: 70px !important;

        @include media-phone {
          max-width: unset;
          max-height: 80px !important;
        }
      }
    }
  }

  .theme-container {
    @include media-tablet {
      display: none;
    }
  }

  .theme-container-mobile {
    display: none;
    @include media-tablet {
      display: unset;
    }
  }

  .menu-mobile {
    display: none;
    align-items: center;
    position: relative;
    padding: $spacing-2;
    margin-left: $spacing-3;
    color: $text-primary;
    @include default-shape;

    :hover {
      background-color: $background-default;
    }

    :focus {
      outline: none;
      border: 1px solid $primary-500;
    }
    @include media-tablet {
      display: block;
    }
  }

  .menu-container {
    display: flex;
    align-items: center;
    font-size: medium;
    font-weight: 600;
    gap: $spacing-7;

    @include media-tablet {
      display: none;
      position: absolute;
      top: $spacing-20;
      width: 100%;
      @include default-shape;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      background-color: $background-paper;
      padding: $spacing-5;
      box-shadow: $box-shadow-lg;

      &--open {
        display: flex;
      }
    }

    .menu-item {
      width: max-content;
      color: $text-primary;
      transition: color 0.3s, background 0.3s;
      padding-block: $spacing-2;
      padding-left: $spacing-3;
      display: flex;
      align-items: center;
      padding-right: $spacing-4;
      white-space: nowrap;
      border-radius: $border-sm;

      &:hover {
        color: $primary-500;
        background-color: transparent;
      }

      @include media-tablet {
        width: 100%;

        &:hover {
          color: $text-primary;
          background-color: $primary-500;
        }
      }
    }
  }
}
