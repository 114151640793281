@import "~@/styles/theme/main.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-4;

    &__label {
      font-size: 20px;
      font-weight: 700;
      color: $text-primary;

      &__divider {
        width: 100%;
        border-bottom: 1px solid $text-secondary;
      }
    }

    &__hours {
      width: 100%;
      display: flex;
      align-items: center;
      gap: $spacing-2;
      font-size: 12px;
      color: $text-secondary;
    }
  }
}
