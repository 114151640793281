@import "~@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-1;
  flex-wrap: wrap;

  @include media-phone {
    justify-content: flex-start;
    gap: $spacing-3;
    margin-top: $spacing-3;
  }

  &__button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      @include background-with-contrast($primary-600);
    }
  }
}
