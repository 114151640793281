@import "~@/styles/theme/main.scss";
.container {
  position: relative;

  .input_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    .input_container {
      display: flex;
      flex-direction: column;

      label {
        font-size: 14px;
        font-weight: 400;
      }

      input {
        width: 100%;
        height: 1.8rem;
        border: 2px solid;
        border-radius: 4px;
        padding-left: 5px;
        font-size: 14px;
        border: 1px solid $primary-500;

        &:focus,
        &:focus-visible {
          border: 1px solid $primary-500;
          outline: none;
        }
      }
    }
  }

  .buttons_container {
    margin-top: 20px;
    padding: 10px 0px;
    display: flex;
    gap: 10px;
    flex-direction: column;

    .default_buttons {
      @include default-shape;
      width: max-content;
      padding: 2px 5px;
      border-radius: 4px;
      border: 1px solid $primary-500;
      font-size: 14px;
      font-weight: 400;
      // color: $primary-800;
    }
  }
}
