@import "~@/styles/theme/main.scss";
.main {
  display: grid;
  grid-column: span 3;
  align-content: space-between;
  &--4col {
    grid-column: span 4;
  }

  .wrapper {
    width: 100%;
    margin-top: 0;
    display: grid;
    grid-column: span 3;

    @include media-desktop {
      grid-column: span 3;
    }

    @include media-tablet {
      margin-top: $spacing-6;
    }

    & h2,
    h1 {
      font-size: 18px;
      font-weight: 700;
    }

    & h2 {
      display: none;

      @include media-desktop {
        display: block;
      }
    }

    .vehicle-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $spacing-8;
      padding-bottom: $spacing-10;

      @include media-tablet {
        grid-template-columns: repeat(2, 1fr);
        gap: $spacing-6;
      }

      @include media-phone {
        grid-template-columns: repeat(1, 1fr);
        gap: $spacing-4;
      }
    }
  }
}
