@import "~@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;

  .input-wrapper {
    position: relative;

    .input {
      @include default-shape;
      appearance: none;
      border-radius: 3px !important;
      border: 1px solid #606060;
      border-color: $primary-300;
      width: $spacing-3;
      height: $spacing-3;
      background-color: $primary-100;
      transition: background 0.3s;
      cursor: pointer;

      &:checked {
        background-color: $primary-600;
      }

      @include media-phone {
        width: 17px;
        height: 17px;
      }
    }

    .icon {
      opacity: 0;
      width: $spacing-3;
      height: $spacing-3;
      position: absolute;
      top: 44%;
      left: 40%;
      transform: translate(-40%, -40%);
      transition: opacity 0.3s;
      margin-top: -1px;
      color: white;
      padding: 2px;
      pointer-events: none;

      &--visible {
        opacity: 1;
      }

      @include media-phone {
        top: 37.5%;
        width: 17px;
        height: 17px;
      }
    }
  }

  label {
    margin-left: $spacing-1;
    min-width: 0;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;

    strong {
      margin-left: $spacing-1;
    }
  }
}
